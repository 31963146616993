/* src/Quiz.css */
.quiz-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .option-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .option-button.correct {
    background-color: green;
    color: white;
  }

  .option-button.incorrect {
    background-color: red;
    color: white;
  }

  .option-button:disabled {
    cursor: not-allowed;
  }
