body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  background-color: white;
  background: linear-gradient(to bottom right, rgba(255, 0, 0, 0.1), rgba(255, 165, 0, 0.1),rgb(255, 255, 255), rgba(255, 255, 0, 0.1), rgb(255, 255, 255),rgba(0, 128, 0, 0.1), rgba(0, 0, 255, 0.1), rgba(75, 0, 130, 0.1), rgba(238, 130, 238, 0.1));


}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.logo-link {
  display: block;
  padding: 0.375rem;
}

.logo-img {
  height: 2rem;
  width: auto;
}

.menu-button {
  display: none;
}

.menu-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  border-radius: 0.375rem;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.menu-links {
  display: flex;
  gap: 3rem;
}

.menu-link {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 600;
}

.menu-link.login {
  margin-left: auto;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 100;
}

.mobile-menu-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-close {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  border-radius: 0.375rem;
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-menu-links {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.hero {
  position: relative;
  padding: 3rem 1.5rem;
  text-align: center;
  height: 100vh;
}

.hero-background {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom right, red, orange, yellow, green, blue, indigo, violet);
  opacity: 0.7;
  z-index: -1;
  animation: rainbowBackground 15s ease infinite;
}

@keyframes rainbowBackground {
  0%, 100% {
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0.1), rgb(255, 255, 255), rgba(0, 0, 255, 0.1), rgba(75, 0, 130, 0.1), rgba(238, 130, 238, 0.1));
  }
  50% {
    background: linear-gradient(to bottom right, rgba(255, 0, 0, 0.1), rgba(255, 165, 0, 0.1),rgb(255, 255, 255), rgba(255, 255, 0, 0.1), rgb(255, 255, 255),rgba(0, 128, 0, 0.1), rgba(0, 0, 255, 0.1), rgba(75, 0, 130, 0.1), rgba(238, 130, 238, 0.1));
  }
  100% {
    background: linear-gradient(to bottom left,  rgb(255, 255, 255), rgba(75, 0, 130, 0.1), rgba(0, 0, 255, 0.1), rgba(0, 128, 0, 0.1), rgba(255, 255, 0, 0.1), rgb(255, 255, 255),rgb(255, 255, 255),);
  }
}




.hero-content {
  max-width: 36rem;
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Optional: Set height to full viewport height */
}

.announcement {
  display: none;
  margin-bottom: 1.5rem;
}

.announcement-text {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #4a4a4a;
  border: 1px solid #d4d4d4;
  border-radius: 9999px;
}

.announcement-link {
  font-weight: 600;
  color: #4c51bf;
  text-decoration: none;
}

.hero-text h1 {
  font-size: 2.25rem;
  font-weight: bold;
  color: #1a1a1a;
}

.hero-text p {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  color: #4a4a4a;
}

.hero-buttons {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.btn {
  display: inline-block;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.btn-primary {
  background-color: #4c51bf;
  color: white;
}

.btn-primary:hover {
  background-color: #3b46a3;
}

.btn-secondary {
  color: #1a1a1a;
}

.btn-secondary:hover {
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .menu-button {
    display: block;
  }

  .menu-links {
    display: none;
  }

  .announcement {
    display: block;
  }
}
